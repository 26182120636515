import React, { useEffect, useState } from "react";

const BankOfAmerica = ({ contextToken, onPaymentProcessed, fedexSelected, orderStatus, paymentTransactionRes }) => {
  const [loading, setLoading] = useState(false); // Initialize loading state

  useEffect(() => {
    const captureContext = contextToken;
    const payButton = document.getElementById("pay-button");

    const myStyles = {
      input: {
        fontSize: "14px",
        fontFamily: "helvetica, tahoma, sans-serif",
        color: "#555",
      },
      ":focus": { color: "blue" },
      ":disabled": { cursor: "not-allowed" },
      valid: { color: "#3c763d" },
      invalid: { color: "#a94442" },
    };

    const flex = new Flex(captureContext);
    const microform = flex.microform({ styles: myStyles });
    const number = microform.createField("number", {
      placeholder: "Enter card number",
    });
    const securityCode = microform.createField("securityCode", {
      placeholder: "•••",
    });

    number.load("#number-container");
    securityCode.load("#securityCode-container");

    const handlePayment = () => {
      const options = {
        expirationMonth: document.querySelector("#expMonth").value,
        expirationYear: document.querySelector("#expYear").value,
      };

      microform.createToken(options, function (err, token) {

        if (err) {
          console.error(err);
          // Handle error messages here
          if (err?.details?.length > 0) {
            err.details.forEach((detailElement) => {
              if (detailElement.location === "number") {
                document.getElementById("number-errors").textContent = `${detailElement.message} in ${detailElement.location}`;
              } else if (detailElement.location === "securityCode") {
                document.getElementById("securityCode-errors").textContent = `${detailElement.message} in ${detailElement.location}`;
              } else {
                document.getElementById("field-errors").textContent = `${detailElement.message} in ${detailElement.location}`;
              }
            });
          }
          setTimeout(() => {
            setLoading(false);
            payButton.disabled = false; // Re-enable the button after the response
          }, 2000);
        } else {
          setLoading(true); // Set loading to true when payment starts
          payButton.disabled = true; // Disable the button when clicked

          onPaymentProcessed(token, fedexSelected, orderStatus);
          setTimeout(() => {
            setLoading(false);
            payButton.disabled = false; // Re-enable the button after the response
          }, 3000);// Reset loading state after processing

        }
      });
    };

    payButton.addEventListener("click", handlePayment);

    return () => {
      payButton.removeEventListener("click", handlePayment);
    };
  }, [contextToken, fedexSelected, orderStatus, paymentTransactionRes]);

  return (
      <div className="container">
        <div id="errors-output" style={{ color: "red" }} role="alert"></div>
        <div className="form-group card_number_main">
          <label id="cardNumber-label" className="font-semibold">Card Number</label>
          <div id="number-container" className="form-control"></div>
          <div id="number-errors" style={{ color: "red" }} role="alert"></div>
        </div>

        <div className="row mt-2">
          <div className="col-md-12 security_number_main">
            <label htmlFor="securityCode-container" className="font-semibold">CVV</label>
            <div id="securityCode-container" className="form-control"></div>
            <div id="securityCode-errors" style={{ color: "red" }} role="alert"></div>
          </div>
          <div className="form-group col-md-6 expiry_select_main">
            <label htmlFor="expMonth" className="font-semibold">Expiry Month</label>
            <select id="expMonth" className="form-select">
              {[...Array(12).keys()].map((m) => (
                  <option key={m + 1} value={`0${m + 1}`.slice(-2)}>
                    {`0${m + 1}`.slice(-2)}
                  </option>
              ))}
            </select>
          </div>
          <div className="form-group col-md-6 expiry_select_main">
            <label htmlFor="expYear" className="font-semibold">Expiry Year</label>
            <select id="expYear" className="form-select">
              {[...Array(10).keys()].map((y) => (
                  <option key={y + 2024} value={y + 2024}>
                    {y + 2024}
                  </option>
              ))}
            </select>
          </div>
        </div>
        <div id="field-errors" style={{ color: "red" }} role="alert"></div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <button
              type="button"
              id="pay-button"
              className="w-full h-[50px] black-btn flex justify-center items-center bank_of_america_btn_main"
              disabled={loading} // Disable the button when loading
          >
            Place Order
            {loading && ( // Conditionally render the loader
                <div style={{ marginLeft: "10px" }} className="payment_loader_main">
                  <span className="loader"></span>
                </div>
            )}
          </button>
        </div>
        <input type="hidden" id="flexresponse" name="flexresponse" />
      </div>
  );
};

export default BankOfAmerica;
